import React, { useState } from 'react';
import logo from './me.PNG';
import art1 from './art1.jpg';
import art2 from './art2.jpg';
import art3 from './art3.jpg';
import art4 from './art4.jpg';
import art5 from './art5.jpg';
import art6 from './art6.jpeg';
import art7 from './art7.jpeg';
import art8 from './art8.jpg';
import visityLogo from "./LogoBlue.png";
import visityHome from "./visity_main.PNG";
import foodxHome from "./foodx_main.PNG";
import foodxPlan from "./foodx_main2.PNG";
import './App.css';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';

function App() {
  const [artData, setArtData] = useState([
    {
      img: art1,
      title: 'art1',
      author: "benny",
      cols: 1
    },
    {
      img: art2,
      title: 'art2',
      author: "benny",
      cols: 1
    },
    {
      img: art3,
      title: 'art3',
      author: "benny",
      cols: 2
    },
    {
      img: art4,
      title: 'art4',
      author: "benny",
      cols: 1
    },
    {
      img: art5,
      title: 'art5',
      author: "benny",
      cols: 1
    },
    {
      img: art6,
      title: 'art6',
      author: "benny",
      cols: 1
    },
    {
      img: art7,
      title: 'art7',
      author: "benny",
      cols: 1
    },
    {
      img: art8,
      title: 'art8',
      author: "benny",
      cols: 2
    },
  ])

  const [homePage, setHomePage] = useState(true)
  const [hobbyPage, sethobbyPage] = useState(false)
  const [webDevPage, setWebDevPage] = useState(false)

  const switchHomePage = () => {
    setHomePage(true)
    sethobbyPage(false)
    setWebDevPage(false)
  }

  const switchHobbyPage = () => {
    setHomePage(false)
    sethobbyPage(true)
    setWebDevPage(false)
  }

  const switchWebDevPage = () => {
    setHomePage(false)
    sethobbyPage(false)
    setWebDevPage(true)
  }

  return (
    <div className="app">
      <AppBar position="fixed" style={{ backgroundColor: "black" }}>
        <Toolbar style={{ display: "flex", justifyContent: "center" }}>
          <Button onClick={() => switchWebDevPage()}><Typography style={webDevPage ? { color: "gray" } : { color: "white" }} variant="p" className="navBarButtons" >web-dev</Typography></Button>
          <Button onClick={() => switchHomePage()}><Typography style={homePage ? { color: "gray" } : { color: "white" }} variant="p" className="navBarButtons" >home</Typography></Button>
          <Button onClick={() => switchHobbyPage()}><Typography style={hobbyPage ? { color: "gray" } : { color: "white" }} variant="p" className="navBarButtons" >hobbies</Typography></Button>
        </Toolbar>
      </AppBar>

      {
        homePage &&
        <div className="homeContent">
          <div id="main_hello">
            <p>hey!</p>
            <p>my name is ben/benney</p>
            <p>i'm a <span style={{ textDecoration: "underline" }}>web developer</span></p>
          </div>
          <div id="main_pic_container">
            <img
              id="main_pic" src={logo} className="App-logo" alt="logo" />
          </div>
        </div>
      }
      {
        hobbyPage &&
        <div className="hobbiesPage">
          <div
            style={{
              paddingTop: "2.5em",
              paddingLeft: "1em",
              width: "50%",
              height: "100%",
              color: "Black",
              fontFamily: "Reenie Beanie",
              letterSpacing: "5px",
              fontSize: "1.5em",
              display: "flex",
              justifyContent: "center",
              width: "98%"
            }}>
            <p>I am an artist! character design and story writing is the most fun to me</p>
          </div>
          <GridList style={{ width: "100%" }} cellHeight={500} cols={2}>
            {artData.map((tile, i) => (
              <GridListTile key={i} cols={tile.cols || 1}>
                <img src={tile.img} alt={tile.title} />
              </GridListTile>
            ))}
          </GridList>
        </div>
      }
      {
        webDevPage &&
        <div className="webDevContent">
          <div id="visity_content_container">
            <img
              id="visity_logo" src={visityLogo} className="App-logo" alt="logo" />
            <p style={{ color: "black", marginRight: "1em", letterSpacing: "3px", fontSize: ".9em", paddingTop: ".5em" }}><span style={{ textDecoration: "underline" }}>Visity</span> is a website that connects travelers with locals to provide the travelers with an authentic experience and in return provide the locals with some business and money-making. <a href="https://visity.co/" target="blank">https://visity.co/</a></p>
          </div>
          <img
            id="visity_home" src={visityHome} className="App-logo" alt="logo" />

          <div style={{ backgroundColor: "black", height: "3em", width: "100%" }} />
          <div id="foodx_content_container" >
            <img id="foodx_home" src={foodxHome} alt="logo" />
            <p id="foox_text"><span style={{ textDecoration: "underline" }}>FoodX</span> is a meal plan service where a user is allowed a plan a day, the website connects to food info API's stores the information on a firebase database and then uses said data to calculate calories and macros. I have been the head developer of this project for months, we took time off due to school but i work on it every once in a bit.</p>
          </div>
          <img id="foodx_plan" src={foodxPlan}/>
        </div>
      }
      <footer className="bottom">mahmudbennbaia@gmail.com</footer>
    </div>
  );
}

export default App;
